import React from "react"
import { CardElement } from "@stripe/react-stripe-js"
import { Container } from "./styled.js"

const InputStripe = ({ name, label, req }) => {
  return (
    <Container>
      <label htmlFor={name} className={!label ? "hidden" : ""}>
        {`${label}`} {req ? <span className="error">*</span> : null}
      </label>
      <CardElement />
    </Container>
  )
}

export default InputStripe
