import React, { Fragment } from "react"
import { Container } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default ({ name, icon, click }) => {
  return (
    <Fragment>
      {name === "Back" ? (
        <Container type="button" className="back" onClick={() => click(0)}>
          <span>
            {icon && <FontAwesomeIcon icon={icon} />}
            <span>{name}</span>
          </span>
        </Container>
      ) : (
        <Container type="button" onClick={() => click(0)}>
          <span>
            <span>{name}</span>
            {icon && <FontAwesomeIcon icon={icon} />}
          </span>
        </Container>
      )}
    </Fragment>
  )
}
