import React from "react"
import { Container } from "./styled.js"

function isFieldError(array, val) {
  return array.includes(val)
}

const Input = ({
  type,
  label,
  note = "",
  req,
  name,
  value,
  update,
  errors,
  autocomplete = name,
  smaller = false,
}) => {
  return (
    <Container className={smaller ? "smaller" : ""}>
      <label htmlFor={name} className={!label ? "hidden" : ""}>
        {`${label}`} {req ? <span className="error">*</span> : null}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        defaultValue={value || ""}
        onChange={e => update(name, e.target.value)}
        autoComplete={autocomplete}
        className={isFieldError(errors, name) ? "error-border" : ""}
      />
      <small className={note ? "" : "hidden"}>{note}</small>
    </Container>
  )
}

export default Input
