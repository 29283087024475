import React from "react"
import { Container } from "./styled.js"

function isFieldError(array, val) {
  return array.includes(val)
}

const Select = ({
  label,
  req,
  name,
  data,
  value,
  update,
  errors,
  side = false,
}) => {
  return (
    <Container className={side ? "side" : ""}>
      <label htmlFor={name} className={!label ? "hidden" : ""}>
        {`${label}`} {req ? <span className="error">*</span> : null}
      </label>
      <select
        name={name}
        id={name}
        defaultValue={value || ""}
        onBlur={e => update(name, e.target.value)}
        autoComplete="off"
        className={isFieldError(errors, name) ? "error-border" : ""}
      >
        {data.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          )
        })}
      </select>
    </Container>
  )
}

export default Select
