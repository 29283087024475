import React from "react"
import { Container } from "./styled.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimes,
  faCheck,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons"

const Msg = ({ data }) => {
  return (
    <Container className={`${data.type}`}>
      {data.type === "error" ? (
        <FontAwesomeIcon icon={faTimes} size="lg" />
      ) : data.type === "success" ? (
        <FontAwesomeIcon icon={faCheck} size="lg" />
      ) : (
        <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
      )}
      <span>{data.text}</span>
    </Container>
  )
}

export default Msg
