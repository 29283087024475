import React, { useState, useRef } from "react"
import { API_URL } from "../../../utils/helpers"
import { db } from "../../../utils/firebase"
import Input from "../../Input"
import Msg from "../../Msg"
import Submit from "../../Submit"
import { Container } from "./styled"
import { Form } from "../../../elements/Layout/styled"
import logo from "../../../img/coolinarycafe-logo-white.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRoute, faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"
import {
  faInstagram,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  const [errors, setErrors] = useState([])
  const [names, setNames] = useState({})
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  const site = "coolinarycafe"

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/email`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => data.append(key, value))
    data.append("site", site || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        db.collection(`emails`)
          .add({
            site: site,
            name: names.name,
            email: names.email,
            comments: names.comments,
            createdAt: new Date(),
          })
          .then(function(docRef) {
            //console.log("Document written with ID: ", docRef.id)
          })
          .catch(function(error) {
            //console.error("Error adding document: ", error)
          })

        formElement.current.reset()
        handleUpdate("name", "")
        handleUpdate("email", "")
        handleUpdate("comments", "")
        setMsg({
          type: "success",
          text: json.text,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Container>
      <div>
        <div>
          <h2>Order Pickup</h2>
          <p>
            4650 Donald Ross Road Suite 110
            <br />
            Palm Beach Gardens, FL 33418
          </p>
          <span>
            <a href="https://www.google.com/maps/place/Coolinary+Café/@26.8816,-80.1094674,17z/data=!3m2!4b1!5s0x88ded585acee258b:0x6a01047142961150!4m5!3m4!1s0x88ded585a723977b:0xd7f3f8f94ac0e073!8m2!3d26.8815952!4d-80.1072734">
              <FontAwesomeIcon icon={faRoute} size="2x" />
            </a>
          </span>
        </div>

        <div>
          <h2>Reach Out</h2>
          <p>
            (561) 249-6760
            <br />
            <a href="mailto:info@thecoolpig.com">info@thecoolpig.com</a>
          </p>
          <span>
            <a href="https://www.instagram.com/coolinarycafe/">
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
            <a href="https://www.facebook.com/CoolinaryCafe/">
              <FontAwesomeIcon icon={faFacebook} size="lg" />
            </a>
            <a href="https://twitter.com/coolinarycafe">
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </a>
          </span>
        </div>
        <div>
          <h2>Send an Email</h2>

          <Form
            method="post"
            action="/"
            onSubmit={e => handleSubmit(e)}
            className="email"
            ref={formElement}
          >
            <div>
              <Input
                type="text"
                label="Name"
                req={true}
                name="name"
                value={names.name}
                update={handleUpdate}
                errors={errors}
                autocomplete="name"
                smaller={true}
              />
            </div>

            <div>
              <Input
                type="text"
                label="Email"
                req={true}
                name="email"
                value={names.email}
                update={handleUpdate}
                errors={errors}
                autocomplete="email"
                smaller={true}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Message"
                req={true}
                name="comments"
                value={names.comments}
                update={handleUpdate}
                errors={errors}
                smaller={true}
              />
            </div>

            <div className="msg-submit">
              {msg.type && <Msg data={msg} />}

              <div
                className={
                  msg.type === "working" ? "hidden" : "submit-container"
                }
              >
                <Submit
                  name="Send Email"
                  icon={faChevronCircleRight}
                  smaller={true}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div className="logo">
        <img src={logo} alt="Coolinary Cafe" />
      </div>

      <p className="copy">
        © 2020 Menuline Online Ordering for Coolinary Cafe. All rights reserved.
      </p>
    </Container>
  )
}

export default Footer
