import React from "react"
import { Container } from "./styled.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingBag } from "@fortawesome/pro-light-svg-icons"

const Cart = ({ num, started }) => {
  return (
    <Container className={num > 0 ? "" : "hidden"}>
      <button type="button" onClick={() => started(1)}>
        <span>
          <FontAwesomeIcon icon={faShoppingBag} size="lg" />
          <span>{num === 1 ? `${num} item` : `${num} items`}</span>
        </span>
      </button>
    </Container>
  )
}

export default Cart
