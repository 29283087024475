import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & label {
    padding-bottom: 5px;
    cursor: pointer;
  }

  &.smaller label {
    font-size: 0.925rem;
  }

  & label.hidden {
    display: none;
  }

  & label > span.error {
    color: var(--error);
  }

  & input[type="text"] {
    margin: 0;
    width: 100%;
    border: 1px solid var(--border);
    background-color: #fff;
    border-radius: 2px;
    vertical-align: middle;
    padding: 7.5px;
    font-size: 16px;
    font-weight: bolder;
    outline: none;
    box-shadow: 0 1px 3px 0 var(--lightgrey);
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    -webkit-appearance: none;
  }

  &.smaller input[type="text"] {
    padding: 3.5px;
    box-shadow: none;
  }

  & input[type="text"].error-border {
    border-color: var(--error);
  }

  & input[type="text"]:focus {
    border-color: var(--blue);
  }

  & small {
    padding-top: 5px;
    line-height: 18px;
  }

  & small.hidden {
    display: none;
  }
`
