import React, { useState, useEffect, Fragment } from "react"
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import {
  API_URL,
  getObjFromLS,
  setObjToLS,
  fmtCurrency,
} from "../../../utils/helpers"
import { db } from "../../../utils/firebase"
import Button from "../../Button"
import Select from "../../Select"
import Input from "../../Input"
import InputStripe from "../../InputStripe"
import Msg from "../../Msg"
import Submit from "../../Submit"
import Alert from "../../Alert"
import { Container } from "./styled"
import { H1, Table, Form } from "../../../elements/Layout/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons"

const Review = ({ started, submitted }) => {
  const [items, setItems] = useState([])
  const [times, setTimes] = useState([])
  const [sides, setSides] = useState([])
  const [errors, setErrors] = useState([])
  const [names, setNames] = useState({})
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })
  const stripe = useStripe()
  const elements = useElements()

  const site = "coolinarycafe"
  const menu = "pickle"

  useEffect(() => {
    if (getObjFromLS(menu)) {
      init(getObjFromLS(menu))
    } else {
      started("")
    }
  }, [started, submitted])

  const init = async array => {
    const url = new URL(`${API_URL}/reviewInit`)
    const params = {
      site,
      menu,
    }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setTimes(json.times)
        setSides(json.sides)
        setItems(array)
        setAlert({})
      } else {
        setAlert({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  let total = 0
  let tax = 0
  let tip = 0

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleUpdateSide = (index, value) => {
    let array = items
    if (index !== undefined) {
      array[index].side = value
    }
    setItems([...array])
    setObjToLS(menu, array)
  }

  const handleRemove = index => {
    let array = items
    array.splice(index, 1)
    setItems([...array])
    setObjToLS(menu, array)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    if (!stripe || !elements) {
      setMsg({
        type: "error",
        text: "An error has occurred loading payment method.",
      })
      return
    }

    const url = new URL(`${API_URL}/payment`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => data.append(key, value))
    data.append("site", site || "")
    data.append("menu", menu || "")
    data.append("numItems", items.length)
    data.append("amt", total + tax + tip)

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        handlePayment(json.clientSecret)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handlePayment = async clientSecret => {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${names.fname} ${names.lname}`,
          email: `${names.email}`,
        },
      },
    })

    if (result.error) {
      setMsg({
        type: "error",
        text: result.error.message,
      })
    } else {
      if (result.paymentIntent.status === "succeeded") {
        handleSuccess(result.paymentIntent.payment_method)
      }
    }
  }

  const handleSuccess = async paymentMethod => {
    const url = new URL(`${API_URL}/submit`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => data.append(key, value))
    data.append("site", site || "")
    data.append("menu", menu || "")
    data.append("items", JSON.stringify(items) || [])
    data.append("tax", tax)
    data.append("tip", tip)
    data.append("total", total)
    data.append("stripePaymentMethod", paymentMethod)

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        db.collection(`orders`)
          .add({
            ...json,
            createdAt: new Date(),
          })
          .then(function(docRef) {
            //console.log("Document written with ID: ", docRef.id)
            const details = { ...json }
            setObjToLS(menu, null)
            setMsg({})
            submitted(details)
          })
          .catch(function(error) {
            //console.error("Error adding document: ", error)
          })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Container>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <Fragment>
          <H1>
            Please review your selected items and enter your payment information
            below to submit your order.
          </H1>

          <div className="back">
            <Button name="Back" icon={faChevronCircleLeft} click={started} />
          </div>

          <Fragment>
            {items.length === 0 ? (
              <Alert
                data={{
                  type: "error",
                  text: "No items selected.",
                }}
              />
            ) : (
              <Fragment>
                <Table>
                  <thead>
                    <tr>
                      <th>Qty</th>
                      <th className="left">Item</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {items.map((item, index) => {
                      const subtotal = item.qty * item.amt
                      total = total + subtotal
                      tax = total * 0.07
                      tip = total * 0.15

                      return (
                        <tr key={index}>
                          <td className="center">{item.qty}</td>
                          <td className="sammie">
                            <div>
                              <div>
                                <span>{item.name}</span>
                                <div className={item.side ? "" : "hidden"}>
                                  <Select
                                    label=""
                                    req={true}
                                    name={index}
                                    value={item.side}
                                    data={sides}
                                    update={handleUpdateSide}
                                    errors={errors}
                                    side={true}
                                  />
                                </div>
                              </div>
                              <button
                                type="button"
                                className="trash"
                                onClick={() => handleRemove(index)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                              </button>
                            </div>
                          </td>
                          <td className="center">{`${item.price}`}</td>
                          <td className="right">
                            {fmtCurrency(subtotal / 100)}
                          </td>
                        </tr>
                      )
                    })}
                    <tr>
                      <td colSpan="3" className="right">
                        Sales Tax
                      </td>
                      <td className="right">{fmtCurrency(tax / 100)}</td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="right">
                        15% Gratuity
                      </td>
                      <td className="right">{fmtCurrency(tip / 100)}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan="3" className="right">
                        TOTAL
                      </th>
                      <td className="right">
                        {fmtCurrency((total + tax + tip) / 100)}
                      </td>
                    </tr>
                  </tfoot>
                </Table>

                <Table className="notes">
                  <tbody>
                    <tr>
                      <th className="right">Pickup:</th>
                      <td className="left" valign="top">
                        Orders will be ready for curbside pick-up from 11am to
                        8pm.
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Form method="post" action="/" onSubmit={e => handleSubmit(e)}>
                  <div className="two">
                    <div>
                      <Select
                        label="Pickup Time"
                        req={true}
                        name="pickup"
                        value=""
                        data={times}
                        update={handleUpdate}
                        errors={errors}
                      />
                    </div>
                    <div>
                      <Input
                        type="text"
                        label="Order Notes"
                        req={false}
                        name="notes"
                        value={names.notes}
                        update={handleUpdate}
                        errors={errors}
                      />
                    </div>
                  </div>

                  <div className="two">
                    <div>
                      <Input
                        type="text"
                        label="First Name"
                        req={true}
                        name="fname"
                        value={names.fname}
                        update={handleUpdate}
                        errors={errors}
                        autocomplete="given-name"
                      />
                    </div>
                    <div>
                      <Input
                        type="text"
                        label="Last Name"
                        req={true}
                        name="lname"
                        value={names.lname}
                        update={handleUpdate}
                        errors={errors}
                        autocomplete="family-name"
                      />
                    </div>
                  </div>

                  <div className="two">
                    <div>
                      <Input
                        type="text"
                        label="Email Address"
                        req={true}
                        name="email"
                        value={names.email}
                        update={handleUpdate}
                        errors={errors}
                        autocomplete="email"
                      />
                    </div>
                    <div>
                      <Input
                        type="text"
                        label="Phone"
                        req={true}
                        name="phone"
                        value={names.phone}
                        update={handleUpdate}
                        errors={errors}
                        autocomplete="tel"
                      />
                    </div>
                  </div>

                  <div>
                    <InputStripe
                      name="card"
                      label="Payment Method"
                      req={true}
                    />
                  </div>

                  <div className="msg-submit">
                    {msg.type && <Msg data={msg} />}

                    <div
                      className={
                        msg.type === "working" ? "hidden" : "submit-container"
                      }
                    >
                      <Submit name="Submit Order" icon={faChevronCircleRight} />
                    </div>
                  </div>
                </Form>
              </Fragment>
            )}
          </Fragment>
        </Fragment>
      )}
    </Container>
  )
}

const Payment = ({ stripePromise, started, submitted }) => {
  return (
    <Elements stripe={stripePromise}>
      <Review started={started} submitted={submitted} />
    </Elements>
  )
}

export default Payment
