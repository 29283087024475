import React from "react"
import { Container } from "./styled.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default ({ name, icon, smaller = false }) => {
  return (
    <Container type="submit" className={smaller ? "smaller" : ""}>
      <span>
        <span>{name}</span>
        {icon && <FontAwesomeIcon icon={icon} size="lg" />}
      </span>
    </Container>
  )
}
