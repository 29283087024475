import styled from "styled-components"

export const Container = styled.div`
  & > div {
    margin: 0 auto;
    width: 30%;

    @media (max-width: 650px) {
      width: 60%;
    }

    & > img {
    }
  }
`
