import React, { useState, useEffect, Fragment } from "react"
import { API_URL, getObjFromLS, setObjToLS } from "../../../utils/helpers"
import Alert from "../../Alert"
import Cart from "../../Cart"
import QtySelect from "../../QtySelect"
import Button from "../../Button"
import { Container } from "./styled"
import { H1, Table } from "../../../elements/Layout/styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Order = ({ started }) => {
  const [details, setDetails] = useState({
    closed: false,
    cats: [],
  })
  const [selected, setSelected] = useState([])
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })

  const site = "coolinarycafe"
  const menu = "pickle"

  useEffect(() => {
    init(site, menu)
  }, [])

  const init = async (site, menu) => {
    const url = new URL(`${API_URL}/menuInit`)
    const params = {
      site,
      menu,
    }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setDetails({
          menu: json.menu,
          title: json.title,
          note: json.note,
          cats: json.cats,
        })
        /*
          if (getObjFromLS(menu)) {
            let array = []
            getObjFromLS(menu).forEach(item => {
              array.push({
                id: item.id,
                qty: item.qty,
              })
            })
            setSelected([...array])
          }
          */
        setAlert({})
      } else {
        setAlert({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handleUpdateQty = (name, id, value) => {
    let array = selected
    let indexToUpdate
    array.forEach((item, index) => {
      if (item.id === id) {
        indexToUpdate = index
      }
    })
    if (indexToUpdate !== undefined) {
      value > 0
        ? (array[indexToUpdate].qty = value)
        : array.splice(indexToUpdate, 1)
    } else {
      array.push({
        id,
        qty: value,
      })
    }
    setSelected([...array])
  }

  const handleContinue = e => {
    let array = []
    details.cats.forEach(cat => {
      cat.items.forEach(item => {
        const found = selected.find(sel => sel.id === item.id)
        if (found) {
          const qty = found.qty
          if (item.side) {
            for (let n = 0; n < qty; n++) {
              array.push({
                qty: 1,
                ...item,
                side: "Fries",
              })
            }
          } else {
            array.push({
              qty,
              ...item,
              side: "",
            })
          }
        }
      })
    })
    let newArray = getObjFromLS(menu) ? getObjFromLS(menu).concat(array) : array
    setObjToLS(menu, newArray)
    started(1)
  }

  return (
    <Container>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <Fragment>
          <H1>{details.title}</H1>
          <Cart
            num={getObjFromLS(menu) ? getObjFromLS(menu).length : 0}
            started={started}
          />
          <Table>
            <tbody>
              {details.cats.map((cat, index) => {
                return (
                  <Fragment key={index}>
                    <Fragment>
                      <tr>
                        <td colSpan="3" className="center hdg">
                          <h3>{cat.name}</h3>
                        </td>
                      </tr>
                      <tr className="hdgs">
                        <th>Qty</th>
                        <th className="left">Item</th>
                        <th>Price</th>
                      </tr>
                    </Fragment>

                    <Fragment>
                      {details.cats[index].items.map(item => {
                        const found = selected.find(sel => sel.id === item.id)
                        return (
                          <tr key={item.id} className={found ? "selected" : ""}>
                            <td className="center qty">
                              <QtySelect
                                name="qty"
                                id={item.id}
                                value={found ? found.qty : 0}
                                data={[
                                  { value: 0, name: "--" },
                                  { value: 1, name: "1" },
                                  { value: 2, name: "2" },
                                  { value: 3, name: "3" },
                                  { value: 4, name: "4" },
                                  { value: 5, name: "5" },
                                  { value: 6, name: "6" },
                                  { value: 7, name: "7" },
                                  { value: 8, name: "8" },
                                  { value: 9, name: "9" },
                                  { value: 10, name: "10" },
                                ]}
                                update={handleUpdateQty}
                              />
                            </td>
                            <td>
                              <div>
                                <span>{item.name}</span>
                                <small
                                  className={!item.description ? "hidden" : ""}
                                >
                                  {item.description}
                                </small>
                              </div>
                            </td>
                            <td className="right">{item.price}</td>
                          </tr>
                        )
                      })}
                    </Fragment>
                  </Fragment>
                )
              })}
            </tbody>
            <tfoot>
              <tr
                className={
                  selected.length === 0 && !getObjFromLS(menu) ? "hidden" : ""
                }
              >
                <td colSpan="3" className="center continue">
                  <Button
                    name="Continue"
                    icon={faChevronCircleRight}
                    click={handleContinue}
                  />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Fragment>
      )}
    </Container>
  )
}

export default Order
