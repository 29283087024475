import React, { useState, Fragment } from "react"
import Alert from "../../Alert"
import { Container } from "./styled"
import { Table } from "../../../elements/Layout/styled"

const Receipt = ({ details }) => {
  const [alert] = useState({})

  return (
    <Container>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <Fragment>
          <Alert
            data={{
              type: "success",
              text: details.hdg,
            }}
          />

          <Table className="receipt">
            <tbody>
              <tr>
                <th className="right">Details:</th>
                <td colSpan="3">{details.title}</td>
              </tr>
              <tr>
                <th className="right">Name:</th>
                <td colSpan="3">{details.name}</td>
              </tr>
              <tr>
                <th className="right">Email:</th>
                <td colSpan="3">{details.email}</td>
              </tr>
              <tr>
                <th className="right">Phone:</th>
                <td colSpan="3">{details.phone}</td>
              </tr>
              <tr>
                <th className="right">Order Notes:</th>
                <td colSpan="3">{details.notes}</td>
              </tr>
            </tbody>
          </Table>

          <br />
          <br />

          <Table>
            <thead>
              <tr>
                <th>Qty</th>
                <th className="left">Item</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>

            <tbody>
              {details.items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="center">{item.qty}</td>
                    <td>{item.name}</td>
                    <td className="center">{`${item.price}`}</td>
                    <td className="right">{item.subtotal}</td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan="3" className="right">
                  Sales Tax
                </td>
                <td className="right">{details.tax}</td>
              </tr>
              <tr>
                <td colSpan="3" className="right">
                  15% Gratuity
                </td>
                <td className="right">{details.tip}</td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <th colSpan="3" className="right">
                  TOTAL
                </th>
                <td className="right">{details.total}</td>
              </tr>
              <tr>
                <td colSpan="3" className="right">
                  {details.paid}
                </td>
                <td className="right">{`(${details.total})`}</td>
              </tr>
            </tfoot>
          </Table>
        </Fragment>
      )}
    </Container>
  )
}

export default Receipt
