import styled from "styled-components"

export const Container = styled.div`
  margin: 25px 0 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  &.working {
    color: var(--working);
  }

  &.success {
    color: var(--success);
  }

  &.error {
    color: var(--error);
  }

  &.notice {
    color: var(--notice);
  }

  & > span {
    padding-top: 15px;
    font-size: 1.25rem;
  }
`
