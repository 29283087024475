import React from "react"
import { Select } from "./styled"

const QtySelect = ({ name, id, data, value, update }) => {
  return (
    <Select
      name={name}
      id={name}
      defaultValue={value || ""}
      onChange={e => update(name, id, e.target.value)}
    >
      {data.map((item, index) => {
        return (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        )
      })}
    </Select>
  )
}

export default QtySelect
