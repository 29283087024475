import styled from "styled-components"

export const Container = styled.div`
  margin: 25px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;

  &.working {
    color: var(--working);
  }

  &.success {
    color: var(--success);
  }

  &.error {
    color: var(--error);
    margin-bottom: 0;
  }

  & > span {
    padding-top: 7.5px;
  }
`
