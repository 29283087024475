import styled from "styled-components"

export const Container = styled.div`
  border-top: 1px solid var(--border);
  padding: 15px 25px 25px 25px;

  @media (max-width: 650px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const H1 = styled.h1`
  color: var(--green);
  text-align: center;
  margin: 0 auto;
  padding: 5px 150px;
  padding-bottom: 25px;
  font-size: 1.5rem;
  line-height: 34px;

  @media (max-width: 1080px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 650px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 1.25rem;
    line-height: 28px;
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  & tr.hidden {
    display: none;
  }

  & tr.hdgs th {
    font-size: 0.9rem;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & tr td {
    background-color: #fff;
  }

  & tr.selected td {
    color: var(--green);
    background-color: var(--lightgreen);
  }

  & th,
  & td {
    border: 1px solid #ddd;
    padding: 10px 7.5px;
  }

  & th {
    background-color: var(--darkgrey);
  }

  & th.left {
    text-align: left;
  }

  & th.right {
    text-align: right;
    vertical-align: top;
  }

  & td.center {
    text-align: center;
  }

  & td.right {
    text-align: right;
  }

  & td.check-select {
    padding: 0;
  }

  & td.hdg {
    background-color: var(--lightgrey);
    padding-top: 25px;
    padding-bottom: 25px;
  }

  & td.qty {
  }

  & tfoot td.continue {
    padding-left: 0;
    padding-right: 0;
    padding-top: 25px;
    padding-bottom: 25px;
    border-left: none;
    border-bottom: none;
    border-right: none;
    background-color: inherit;
  }

  & td > div {
    display: flex;
    flex-direction: column;
  }

  & td > div > span {
    font-size: 1.1rem;
    font-weight: bolder;
  }

  & td > div > small {
    font-size: 0.9rem;
    padding-top: 2.5px;
  }

  &.notes {
    margin-top: 25px;
  }

  &.notes tbody th {
    width: 15%;

    @media (max-width: 650px) {
      width: 20%;
    }
  }

  &.receipt tbody th {
    width: 25%;

    @media (max-width: 650px) {
      width: 30%;
    }
  }

  & td.sammie .hidden {
    display: none;
  }

  & td.sammie > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  & td.sammie > div button.trash {
    margin-left: 15px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    color: var(--error);
  }
`

export const Form = styled.form`
  margin-top: 25px;

  &.email {
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  & > div {
    width: 100%;
    margin-top: 15px;
  }

  & > div.two {
    width: 100%;
    display: flex;
    align-items: flex-start;

    @media (max-width: 650px) {
      flex-direction: column;
    }
  }

  & > div.two > div:first-child {
    margin-right: 7.5px;

    @media (max-width: 650px) {
      margin-right: 0;
    }
  }

  & > div.two > div:last-child {
    margin-left: 7.5px;

    @media (max-width: 650px) {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  & > div.msg-submit {
    margin-top: 30px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
`
