import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & label {
    padding-bottom: 5px;
    cursor: pointer;
  }

  & label.hidden {
    display: none;
  }

  & label > span.error {
    color: var(--error);
  }

  & .StripeElement {
    margin: 0;
    width: 100%;
    border: 1px solid var(--border);
    background-color: #fff;
    border-radius: 2px;
    vertical-align: middle;
    padding: 12.5px 7.5px;
    font-size: 16px;
    font-weight: bolder;
    outline: none;
    box-shadow: 0 1px 3px 0 var(--lightgrey);
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    -webkit-appearance: none;
  }

  & .StripeElement--focus {
    border-color: var(--blue);
  }

  & .StripeElement--invalid {
    color: var(--error);
    border-color: var(--error);
  }

  & .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`
