export const API_URL = "https://menuline.com/api"
export const STRIPE_KEY = "pk_test_jmjIefsDS9bDZxaFFaMiM6ox"

const isBrowser = () => typeof window !== "undefined"

export const getObjFromLS = key =>
  isBrowser() && window.sessionStorage.getItem(key)
    ? JSON.parse(window.sessionStorage.getItem(key))
    : undefined

export const setObjToLS = (key, obj) => {
  if (isBrowser() && obj) {
    window.sessionStorage.setItem(key, JSON.stringify(obj))
  } else {
    window.sessionStorage.removeItem(key)
  }
  return true
}

export function fmtCurrency(int) {
  const amt = int
  return `${amt.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })}`
}
