import React from "react"
import { Container } from "./styled"
import logo from "../../../img/coolinarycafe-logo.png"

const Logo = () => {
  return (
    <Container>
      <div>
        <img src={logo} alt="Coolinary Cafe" />
      </div>
    </Container>
  )
}

export default Logo
